import React from 'react';
import { observer, Observer, useLocalStore } from 'mobx-react-lite';
// utils
import { useInjectStores } from 'utils/utils';
import { useLocation } from 'react-router-dom';
import ReactResizeDetector from 'react-resize-detector';
// components
import SideNavItems from './Sidebar/SideNavItems';
// styling
import styled from 'styled-components/macro';
import { Filter, Header } from '../index';

const Layout = observer(({ children }) => {
  const location = useLocation();
  const { uiStore } = useInjectStores();
  const showSidebar = uiStore.showSidebar,
    toggleHideSidebar = uiStore.toggleHideSidebar;

  const localStore = useLocalStore(() => ({
    onResize(width) {
      if (width < 700 && uiStore.showSidebar) {
        toggleHideSidebar();
      }
      uiStore.setMobileMode(width);
    }
  }));

  return (
    <Observer>
      {() => (
        <>
          {location.pathname === '/login' || location.pathname === 'resetPassword' ? (
            <div className="pageContainer">{children}</div>
          ) : (
            <>
              <ReactResizeDetector handleWidth onResize={localStore.onResize} />
              <Wrapper moveLeft={!showSidebar} show={showSidebar}>
                <Header />
                <div className="mainContentWrapper">
                  <div className="sidebarWrapper">{<div className={'sideContainer'}>{<SideNavItems/>}</div>}</div>
                  <div className="sideBarContainerHolder"/>
                  <div id="main" className="filterPageContainer">
                    <Filter />
                    <div className="pageContainer">{children}</div>
                  </div>
                </div>
              </Wrapper>
            </>
          )}
        </>
      )}
    </Observer>
  );
});

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  display: flex;
  height: 100%;
  width: 100%;

  .filterPageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .mainContentWrapper {
    display: flex;
    margin-top: 44px;
    width: 100%;
    height: 100%;
  }
  .pageContainer {
    margin-top: 4px;
    background: transparent;
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.moveLeft ? '100vw' : 'calc(100vw - 201px)')};
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
  }

  /*sidebar*/
  .clientName {
    font-size: 20px;
    color: #fff;
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .clientNameWrapper {
    display: flex;
    position: relative;
    height: 44px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    width: calc(100% - 5px);
    margin-left: 5px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
  }

  .arrowLeft {
    display: none;
    position: absolute;
    right: 5px;
    top: 6px;
  }

  .toggleArrow {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 47px;
    left: 177px;
    z-index: 1;

    i {
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      margin: 4px 0 0 6px;
    }
  }
  .mobileToggleArrow {
    z-index: 1;
    left: 0;
  }
  .sidebarWrapper {
    position: fixed;
    z-index: 1;
    flex-direction: column;
    min-width: ${(props) => (props.show ? '200px' : '0')};
    width: ${(props) => (props.show ? '200px' : '0')};
    height: 100vh;
    justify-content: center;
    background: transparent;
    transition: all 1s;

    .sieContainer {
      flex-direction: column;
      height: 100%;
      display: flex;
    }
    &:hover {
      .arrowLeft {
        display: flex;
      }
    }
  }
  .sideBarContainerHolder {
    flex-direction: column;
    min-width: ${(props) => (props.show ? '200px' : '0')};
    width: ${(props) => (props.show ? '200px' : '0')};
    height: 100vh;
    justify-content: center;
    background: transparent;
    transition: all 1s;
  }
`;

export default Layout;
