import React, {useEffect, useState} from 'react';
import {Input, RouteLoadingSpinner} from 'components/UI';
import {LockIcon, LoginWrapper, UserIcon} from 'styles/styledComponents';
import {LoginPageWrapper} from './StyledLogin';
import {observer, Observer, useLocalObservable} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {useHistory, useLocation} from 'react-router-dom';
import {commonService} from 'services';
import axios from 'axios';
import logo from 'images/GoldenAit/eightLogoSilver.svg';
import {_get, useInjectStores} from 'utils/utils';

const Logo = styled.div`
  height: ${({theme, height}) => height || theme.loginLogo.height};
  width: ${({theme, width}) => width || theme.loginLogo.width};
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${logo});
`;

const Login = observer(() => {
    const history = useHistory();
    const location = useLocation();
    const {uiStore, userStore, dataManagementStore} = useInjectStores();
    const [loginState, setLoginState] = useState('login');
    const store = useLocalObservable(() => ({
        isLoading: false,
        email: '',
        emailError: '',
        password: '',
        passwordError: '',
        serverError: '',
        setEmailError(error) {
            this.emailError = error;
        },
        setPasswordError(error) {
            this.passwordError = error;
        },
        setServerError(error) {
            this.serverError = error;
        },
        setEmail(e) {
            this.emailError = '';
            this.passwordError = '';
            this.serverError = '';
            this.email = e.target.value;
        },
        setBlurEmail() {
            setTimeout(() => this.validateEmail(this.email), 300);
        },
        setPassword(e) {
            this.emailError = '';
            this.passwordError = '';
            this.serverError = '';
            this.password = e.target.value;
        },
        setBlurPassword() {
            setTimeout(() => this.validatePassword(this.password), 300);
        },
        get creds() {
            return {email: this.email, password: this.password};
        },
        async handleResetPassword(event) {
            event.preventDefault();
            if (this.validateEmail()) {
                try {
                    this.isLoading = true;
                    await commonService.requestResetPassword(this.creds);
                    uiStore.notify({
                        text: `Password reset has been sent to ${this.email}`,
                        group: 'topRight',
                        type: 'success',
                    });
                    setLoginState('login');
                } catch (error) {
                    console.log('error', error);
                }
            }
            this.isLoading = false;
        },
        async login(event) {
            event.preventDefault();
            const validEmail = this.validateEmail();
            const validPassword = this.validatePassword();
            if (validEmail && validPassword) {
                try {
                    this.isLoading = true;
                    const response = await commonService.login(this.creds);
                    let user = response.data;
                    let token = _get(response, 'headers.authorization');
                    if (token) {
                        if(user.clearCache === "true") {
                            window.location.reload(true);
                        }
                        userStore.setUser(user);
                        window.localStorage.setItem('eygSessionToken', `${token}`);
                        axios.defaults.headers.common['authorization'] = token;
                        let initialRoute = '/dashboard';

                        if (user.id === userStore.userMetaData.userID && userStore.userMetaData.toPath) {
                            initialRoute = userStore.userMetaData.toPath;
                        }
                        window.sessionStorage.setItem('dataType', 'current');
                        userStore.setDataType('current');
                        if(user?.currentClient?.permissions?.archive_access === 'Checked') {
                            await dataManagementStore.setCurrentData();
                        }
                        await history.push(initialRoute);
                        // this.isLoading = false;
                    }
                } catch (error) {
                    this.serverError = _get(error, 'response.data.message')
                }
            }
            this.isLoading = false;
        },
        validateEmail() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let ok = re.test(String(this.email).toLowerCase());
            if (!ok) this.emailError = 'Please enter a valid email';
            return ok;
        },
        validatePassword() {
            if (this.password.length === 0) {
                this.passwordError = 'Please enter a valid password';
                return false;
            }
            return true;
        },
    }));

    useEffect(() => {
        async function pushHistoryToLogin() {
            await history.push('/login');
        }

        if (location.pathname !== '/login') {
            pushHistoryToLogin();
        }
    }, []);

    return (
        <Observer>
            {() => (
                <StyleWrapper>
                    <LoginPageWrapper>
                        {store.isLoading ? (
                            <RouteLoadingSpinner size={'400px'}/>
                        ) : (
                            <LoginWrapper flexDirection="column">
                                <Logo/>
                                <div className="loginFormWrapper">
                                    {loginState === 'login' ? (
                                        <form style={{width: '100%'}} onSubmit={store.login}>
                                            <h3 className="login-state-title">Log In</h3>
                                            <div className="inputIconWrapper">
                                                <label>Email address</label>
                                                <Observer>
                                                    {() => (
                                                        <Input
                                                            tabIndex={1}
                                                            marginBottom={'0px'}
                                                            width="100%"
                                                            value={store.email}
                                                            name="email"
                                                            onChange={(e) => store.setEmail(e)}
                                                            setBlur={store.setBlurEmail}
                                                            autoComplete={'username'}
                                                            icon
                                                            border
                                                            autoFocus={true}
                                                            boxShadow={'inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6)'}
                                                        >
                                                            <UserIcon style={{width: 20, height: 20, marginLeft: 5}}/>
                                                        </Input>
                                                    )}
                                                </Observer>
                                                {store.emailError && <Observer>{() => <div
                                                    className="notValidEmail">{store.emailError}</div>}</Observer>}
                                            </div>

                                            <div className="inputIconWrapper">
                                                <label>Password</label>
                                                <Observer>
                                                    {() => (
                                                        <Input
                                                            tabIndex={2}
                                                            width="100%"
                                                            value={store.password}
                                                            name="password"
                                                            marginBottom={'0px'}
                                                            onChange={store.setPassword}
                                                            type="password"
                                                            autoComplete={'current-password'}
                                                            icon
                                                            border
                                                            boxShadow={'inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6)'}
                                                        >
                                                            <LockIcon color="#000"
                                                                      style={{width: 20, height: 20, marginLeft: 5}}/>
                                                        </Input>
                                                    )}
                                                </Observer>

                                                {store.passwordError && (
                                                    <Observer>{() => <div
                                                        className="notValidEmail">{store.passwordError}</div>}</Observer>
                                                )}
                                                {/* <div className="login-state" onClick={() => setLoginState('reset')}>
                                                    Forgot Password
                                                </div> */}
                                            </div>
                                            <Observer>
                                                {() => (
                                                    <SubButton width={'100px'} type="submit">
                                                        Log in
                                                    </SubButton>
                                                )}
                                            </Observer>
                                            {store.serverError && (
                                                <Observer>
                                                    {() => (
                                                        <div className="error">
                                                            <div className="topError">{store.serverError.toUpperCase()}</div>
                                                        </div>
                                                    )}
                                                </Observer>
                                            )}
                                        </form>
                                    ) : null}
                                    {!store.isLoading && loginState === 'reset' && (
                                        <form style={{width: '100%'}} onSubmit={store.handleResetPassword}>
                                            <h3 className="login-state-title">Reset password</h3>
                                            <div className="inputIconWrapper">
                                                <div css={`width: 100%;
                                                  text-align: center;
                                                  color: red;`}> password reset is temporarily disabled
                                                </div>
                                                <br/>
                                                <div css={`width: 100%;
                                                  text-align: center;`}> sorry for the inconvenience
                                                </div>
                                                {/*   <label>Email address</label>
                        <Observer>
                          {() => (
                            <Input
                              tabIndex={1}
                              marginBottom={'0px'}
                              width="100%"
                              value={store.email}
                              onChange={store.setEmail}
                              setBlur={store.setBlur}
                              autoComplete={'username'}
                              icon
                              border
                              autoFocus={true}
                              boxShadow={'inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6)'}
                            >
                              <UserIcon style={{ width: 20, height: 20, marginLeft: 5 }} />
                            </Input>
                          )}
                        </Observer>
                        {store.emailError && (
                          <Observer>{() => <div className="notValidEmail">Please enter a valid email address</div>}</Observer>
                        )}*/}
                                            </div>

                                            <div className="login-state" onClick={() => setLoginState('login')}>
                                                Log in
                                            </div>

                                            {store.serverError && (
                                                <Observer>
                                                    {() => (
                                                        <div className="error">
                                                            <div className="topError">{store.serverError}</div>
                                                        </div>
                                                    )}
                                                </Observer>
                                            )}
                                            {/*<Observer>
                        {() => (
                          <SubButton width={'auto'} type="submit">
                            Reset Password
                          </SubButton>
                        )}
                      </Observer>*/}
                                        </form>
                                    )}
                                </div>
                            </LoginWrapper>
                        )}
                    </LoginPageWrapper>
                </StyleWrapper>
            )}
        </Observer>
    );
});

const SubButton = styled.button`
  display: flex;
  width: ${({width}) => width || '100%'};
  margin: 20px auto 0 auto;
  height: 30px;
  text-align: center;
  align-items: center;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  justify-content: center;
  border: 1px solid #e2e2e2;
  background-color: ${({disabled, theme}) => {
    return !disabled ? '#fff' : theme.baseColors.colorGreyMediumLight;
  }};
  box-shadow: 0 2px 4px 0 #bbbbbb;
  color: ${({disabled, theme}) => (disabled ? theme.baseColors.colorGreyDark : '#4183c4')};

  &:hover {
    cursor: pointer;
    background-color: #e2e2e2;
  }
`;

const StyleWrapper = styled.div`
  .login-state-title {
    width: 100%;
    text-align: center;
    color: ${({theme}) => theme.baseColors.colorGreyDark};
  }

  .login-state {
    width: auto;
    margin: 0 auto;
    text-align: center;
    color: #4183c4;
    cursor: pointer;
    padding-top: 15px;
  }

  .loginLabel {
    display: flex;
    justify-content: center;
    margin: 0 0 10px 0;
    color: #fff;
  }

  .inputIconWrapper {
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-bottom: 6px;
    border-radius: 5px;
    font-weight: normal;

    label {
      font-size: 12px;
      padding: 5px;
    }

    .notValidEmail {
      font-size: 12px;
      color: red;
      margin: 3px;
    }
  }

  .submitLogin {
    display: flex;
    width: 100%;
    height: 30px;
    text-align: center;
    outline: none;
    color: #4183c4;
    font-size: 14px;
    justify-content: center;
    border: 1px solid #e2e2e2;

    &:hover {
      cursor: pointer;
    }
  }

  .loginPage {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .loginFormWrapper {
    display: flex;
    justify-content: center;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 20px;
    background: #fff;
    width: 300px;
    //min-height: 164px;
    min-height: 180px;
  }

  .error {
    margin: 0.5rem 0 0;
    color: ${({theme}) => theme.colors.colorGreyDark};
    background-color: #fdedee;
    border: 1px solid #f8a9ad;
    padding: 10px;

    .topError {
      font-weight: bold;
      //margin-bottom: 5px;
    }

    .bottomError {
      font-size: 14px;
    }
  }

  .error.show {
    display: block;
  }
`;

export default Login;
