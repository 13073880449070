import { removeEmptyValues } from 'utils/utils';
import {
  ChargebackFilterModel,
  CommonFilterModel,
  DateFilterModel,
  DeclinesFilterModel,
  TransactionsFilterModel,
  OpportunityFilterModel,
} from './CommonFilterModels';
import { applySnapshot, types, getSnapshot, destroy } from 'mobx-state-tree';
const { optional } = types;

const FilterStore = types
  .model({
    commonFilter: optional(CommonFilterModel, () => CommonFilterModel.create()),
    chargebackFilter: optional(ChargebackFilterModel, () => ChargebackFilterModel.create()),
    dateFilter: optional(DateFilterModel, () => DateFilterModel.create()),
    declineFilter: optional(DeclinesFilterModel, () => DeclinesFilterModel.create()),
    transactionFilter: optional(TransactionsFilterModel, () => TransactionsFilterModel.create()),
    opportunityFilter: optional(OpportunityFilterModel, () => OpportunityFilterModel.create()),
  })
  .volatile((self) => ({
    initialState: {},
    currentRoute: '',
    appliedCommonFilters: [],
    appliedTransactionFilters: [],
    appliedDeclFilters: [],
    appliedChgbkFilters: [],
    appliedSecondaryFilters: [],
    secondaryFilter: {},
    secondaryFilterName: '',
    apply: false,
  }))
  .views((self) => ({
    get currentFilterData() {
      let common = getSnapshot(self.commonFilter);
      let secondary = {};
      if (self.secondaryFilterName) {
        secondary = getSnapshot(self[self.secondaryFilterName]);
      }
      let filter = {
        ...common,
        ...secondary,
      };
      let filteredFilter = removeEmptyValues(filter);
      return filteredFilter || {};
    },
  }))
  .actions((self) => {
    function removeEmptyStrings(obj) {
      let newObj = {};
      Object.keys(obj).forEach((prop) => {
        if (obj[prop] !== '') {
          newObj[prop] = obj[prop];
        }
      });
      return newObj;
    }
    function afterCreate() {
      self.initialState = getSnapshot(self);
    }
    function remove(item) {
      destroy(item);
    }
    function mapFilterDropdownsToModels() {
      self.transactionFilter.mapTxnDropdownsWithModel();
      self.commonFilter.setClientOptionsFromModel();
    }
    function applyForcedVoidsFilter() {
      applySnapshot(self, { transactionFilter: { txnTypeID: 6, txnStatusIDL: 32 } });
    }
    function removeUnappliedFilters() {
      applySnapshot(self.commonFilter, self.appliedCommonFilters);
      if (self.secondaryFilter) {
        applySnapshot(self[self.secondaryFilterName], self.appliedSecondaryFilters);
      }
    }

    function applyFilter(history, location) {
      self.appliedCommonFilters = removeEmptyValues(getSnapshot(self.commonFilter));
      if (self.secondaryFilterName) {
        self.appliedSecondaryFilters = removeEmptyValues(getSnapshot(self.secondaryFilter));
      }
    }

    function resetApplyFilter() {
      self.apply = false;
    }

    function setCurrentFilter( history, location) {
      // let startDt = self.dateFilter.formatStartDt;
      // let endDt = self.dateFilter.formatEndDt;
      self.currentRoute = location.pathname;
      self.secondaryFilter = null;
      const routes = {
        '/processing/transactions': 'transactionFilter',
        '/processing/declines': 'declineFilter',
        '/processing/chargebacks': 'chargebackFilter',
        '/opportunity': 'opportunityFilter'
      }
      self.secondaryFilterName = routes[location.pathname] || '';
      self.secondaryFilter = self[routes[location.pathname]] || null;
      if (!routes[location.pathname]) {
        self.appliedSecondaryFilters = [];
      }

      self.applyFilter(history, location);
    }

    function resetStateNoApply() {
      self.commonFilter.resetState();
      if (self.secondaryFilter) {
        self.secondaryFilter.resetState();
      }
    }
    function resetState(history, location) {
      self.commonFilter.resetState();
      if (self.secondaryFilter) {
        self.secondaryFilter.resetState();
      }
      applyFilter(history, location);
    }
    function resetErrors() {
      self.commonFilter.resetErrors();
      self.transactionFilter.resetErrors();
      self.declineFilter.resetErrors();
      self.chargebackFilter.resetErrors();
      self.opportunityFilter.resetErrors();
    }

    return {
      removeEmptyStrings,
      afterCreate,
      remove,
      mapFilterDropdownsToModels,
      applyForcedVoidsFilter,
      removeUnappliedFilters,
      applyFilter,
      resetApplyFilter,
      setCurrentFilter,
      resetStateNoApply,
      resetState,
      resetErrors,
    };
  });

export default FilterStore;
