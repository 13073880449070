import React, {useEffect} from 'react';
/* state management */
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
/* styles */
import styled from 'styled-components/macro';
import {Divider, WidgetBody, WidgetHeader, WidgetWrapper} from 'styles/styledComponents';
import {Dropdown, Error, LoadingSpinner} from 'components/UI';
/* Styled Components */
import {RadialBarLegend} from './resources';
import {PaginatorModel} from 'stores/CommonFilterModels';
import Paginator from './procLimitPaginator';
/* utils */
import {_sortBy, _chunk, _find, _findIndex, _get, _round, useGetWidgetData, useInjectStores} from 'utils/utils';
import {widgetService} from 'services';
import Moment from "moment";

const SsProcLimits = observer(() => {
    const {dataManagementStore, uiStore, userStore, utilsStore} = useInjectStores();
    const toPercent = utilsStore.toPercent,
        getPercentage = utilsStore.getTotalsPercentage,
        toCurrency = utilsStore.toCurrency,
        showSidebar = uiStore.showSidebar;
    const store = useLocalObservable(() => ({
        paginator: PaginatorModel.create({txnLimit: 3, pageNumber: 1}),
        dataManagementStore,
        uiStore,
        utilsStore,
        toPercent,
        toCurrency,
        loading: false,
        procCurrent: [],
        procHistory: [],
        selectedOption: null,
        setLoading(loading) {
            this.loading = loading;
        },
        setProcCurrent(data) {
            this.procCurrent = _get(data, 'values', null);
        },
        setProcHistory(data) {
            this.procHistory = _get(data, 'values', null);
            /* this.procHistory && this.procHistory.map(month => {
                 if (month.data && month.data.length < 2) {
                     month.data.push({
                         eom: month.txn_dt_eom,
                         mid: "NA",
                         mo_actual_usage: 0,
                         mo_proc_limit: 0.00,
                         // som: month.som,
                         usage_ratio: 0
                     })
                 }
             })*/
            this.selectedOption = _get(data, 'values[0].txn_dt_eom', '');
        },
        selectOption(val) {
            this.selectedOption = val;
        },
        get isDemo() {
            return _get(userStore, 'user.demo', false);
        },
        get procLimits() {
            /*mid:"937500000003285"
            mo_cr_limit:2000000
            mo_cr_limit_ratio:"127.85%"
            mo_cr_limit_stat:"Danger"
            mo_cr_limit_usage:2556943.61
            txn_dt_eom:"2022-08-31T00:00:00Z"*/
            const chunkedLimits = _chunk(
                this.procCurrent?.length > 0 &&
                _sortBy(this.procCurrent.map(({txn_dt_eom, mid, mo_cr_limit_ratio, mo_cr_limit, mo_cr_limit_usage}) => {
                    // const usage = usage_ratio > 100 ? 100 : usage_ratio;
                    return {
                        txn_dt_eom,
                        mid,
                        mo_cr_limit,
                        yAxis: mo_cr_limit_usage >= 100 ? 100 : mo_cr_limit_usage,
                        usage_ratio: mo_cr_limit_ratio,
                        percentUsed: getPercentage(mo_cr_limit_usage, mo_cr_limit) > 100 ? 100 : getPercentage(mo_cr_limit_usage, mo_cr_limit),
                        details: `${toCurrency(mo_cr_limit_usage, 0)} of ${toCurrency(mo_cr_limit, 0)} / period`,
                        label: {
                            date: `${Moment(txn_dt_eom).format('YYYY')} ${Moment(txn_dt_eom).format('MMMM')}`,
                            percent: getPercentage(mo_cr_limit_usage, mo_cr_limit),
                            actual: toCurrency(mo_cr_limit_usage, 0),
                            total: toCurrency(mo_cr_limit, 0)
                        },
                    };
                }), ['mid']),
                3
            );
            return chunkedLimits[this.paginator.pageNumber - 1];
        },

        get dropdownOptions() {
            if (this.procHistory?.length > 0) {
                return this.procHistory.map(({txn_dt_eom}, index) => {
                    return {
                        key: index,
                        text: `${Moment(txn_dt_eom).format('YYYY')}: ${Moment(txn_dt_eom).format('MMMM')}`,
                        value: txn_dt_eom
                    };
                });
            }
        },
        get procLimitsHistory() {
            if (this.selectedOption) {
                const chunkedLimits =
                    this.procHistory?.length &&
                    _sortBy(this.procHistory.map(({
                                                      txn_dt_eom,
                                                      mid,
                                                      mo_cr_limit_ratio,
                                                      mo_cr_limit,
                                                      mo_cr_limit_usage
                                                  }) => {
                        return {
                            txn_dt_eom,
                            mid,
                            mo_cr_limit,
                            yAxis: mo_cr_limit_usage >= 100 ? 100 : mo_cr_limit_usage,
                            usage_ratio: mo_cr_limit_ratio,
                            percentUsed: getPercentage(mo_cr_limit_usage, mo_cr_limit) > 100 ? 100 : getPercentage(mo_cr_limit_usage, mo_cr_limit),
                            details: `${toCurrency(mo_cr_limit_usage, 0)} of ${toCurrency(mo_cr_limit, 0)} / period`,
                            label: {
                                date: `${Moment(txn_dt_eom).format('YYYY')} ${Moment(txn_dt_eom).format('MMMM')}`,
                                percent: getPercentage(mo_cr_limit_usage, mo_cr_limit),
                                actual: toCurrency(mo_cr_limit_usage, 0),
                                total: toCurrency(mo_cr_limit, 0)
                            },
                        };
                    }), ['mid']);
                const blank = {
                    txn_dt_eom: chunkedLimits[0].txn_dt_eom,
                    mid: '',
                    mo_cr_limit: '',
                    yAxis: 100,
                    usage_ratio: 0,
                    percentUsed: '0',
                    details: ` 0 of 0 / period`,
                    label: {
                        date: `${Moment(chunkedLimits[0].txn_dt_eom).format('YYYY')} ${Moment(chunkedLimits[0].txn_dt_eom).format('MMMM')}`,
                        percent: `0`,
                        actual: 0,
                        total: 0
                    },
                }
                const firstIndex = _findIndex(this.procHistory, {txn_dt_eom: this.selectedOption})
                const procHistory = [chunkedLimits[firstIndex]];
                const second = chunkedLimits[firstIndex + 1] ? procHistory.push(chunkedLimits[firstIndex + 1]) : '';
                const third = chunkedLimits[firstIndex + 2] ? procHistory.push(chunkedLimits[firstIndex + 2]) : '';
                return procHistory;
            }
            // return chunkedLimits[this.paginator.pageNumber - 1];
        },

        error: null,
        get radialBarColor() {
            return uiStore.currentTheme.baseColors.colorDanger;
        },
        get theme() {
            return _get(uiStore, 'currentTheme.rgl.gridItem');
        },
        get innerRadius() {
            let numOfLimits = _get(store, 'procLimits.length');
            let radiusOffset = 7;
            return `${80 - numOfLimits * radiusOffset}%`;
        },
        showLegendHist: false,
        showLegendHistIndex: null,
        showProcDetailsDesc: '',
        get showProcDetails() {
            return this.showProcDetailsDesc || this.showLegendHist;
        },
        showProcLimitDetails(stuff) {
            this.showProcDetailsDesc = stuff.details;
        },
        hideProcLimitDetails() {
            this.showProcDetailsDesc = '';
        },
        toggleShowLegendHist(index) {
            this.showLegendHistIndex = index;
        },
        toggleHideLegendHist() {
            this.hideProcLimitHistDetails();
            this.showLegendHistIndex = null;
        },

        showLegend: false,
        showLegendIndex: null,
        showProcHistDetailsDesc: '',
        get showProcHistDetails() {
            return this.showProcHistDetailsDesc || this.showLegend;
        },
        showProcLimitHistDetails(stuff) {
            this.showProcHistDetailsDesc = stuff.details;
        },
        hideProcLimitHistDetails() {
            this.showProcHistDetailsDesc = '';
        },
        toggleShowLegend(index) {
            this.showLegendIndex = index;
        },
        toggleHideLegend() {
            this.hideProcLimitDetails();
            this.showLegendIndex = null;
        },
    }));

    const {
        status: procStatus,
        data: procCurrent,
        error: procError,
    } = useGetWidgetData(
        // {name: 'dashboardProcessingLimit', query: widgetService.dashboardProcessingLimit},
        {name: 'dashboardProcessingLimit', query: widgetService.procLimit},
        'data',
        dataManagementStore?.newQuery
    );
    const {
        status: procHistoryStatus,
        data: procHistory,
        error: procHistoryError,
    } = useGetWidgetData(
        // {name: 'dashboardProcessingLimitHistory', query: widgetService.dashboardProcessingLimitHistory},
        {name: 'dashboardProcessingLimitHistory', query: widgetService.procLimitHist},
        'data',
        dataManagementStore?.newQuery
    );

    useEffect(() => {
        store.setProcCurrent(procCurrent);
    }, [procCurrent]);

    useEffect(() => {
        store.setProcHistory(procHistory);
    }, [procHistory]);

    return (
        <Observer>
            {() => (
                <Wrapper moveLeft={!showSidebar}>
                    <WidgetWrapper height="100%">
                        <WidgetHeader>Processing Limit</WidgetHeader>
                        <StyledNote>
              <span>
                <span className="note">* </span>
                <span className="noteBody">Usage based on txn / auth date</span>
              </span>
                        </StyledNote>
                        <Divider/>
                        <WidgetBody display={'flex'}>
                            {/*{procStatus === 'loading' || procHistoryStatus === 'loading' ? (*/}
                            {procStatus === 'loading' ? (
                                <LoadingSpinner size={'200px'}/>
                                // ) : procError || procHistoryError ? (
                            ) : procError ? (
                                <div
                                    css={`
                                      width: 100%;
                                      align-items: center;
                                      display: flex;
                                      flex-direction: column;
                                    `}
                                >
                                    {/*<Error error={procHistoryError}/>*/}
                                    <Error error={procError}/>
                                </div>
                            ) : store.procLimits?.length > 0 &&
                            store.procLimits[0].details !== '$0 of $0 / period' ?
                                (
                                    <div css={`
                                      display: grid;
                                      grid-template-columns: 1fr 1fr;
                                      grid-template-rows: minmax(300px, 1fr);
                                      width: 100%;
                                      @media (max-width: 1280px) {
                                        grid-template-columns: 1fr;
                                        grid-template-rows: minmax(300px, 1fr) minmax(300px, 1fr);
                                      }
                                    `}>
                                        <section css={`
                                          border-right: 1px solid rgba(255, 255, 255, .1);
                                          height: 100%;
                                          width: 100%;
                                        `}>
                                            <div style={{
                                                margin: '0 0 px 0',
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        top: '15px',
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div style={{height: '21px'}}>Current Month</div>
                                                    {store.procCurrent?.length > 10 && (
                                                        <Paginator
                                                            paginator={store.paginator}
                                                            loading={false}
                                                            fetchTableData={() => {
                                                            }}
                                                            txnCount={store.procCurrent.length}
                                                            height="20px"
                                                            width="25px"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <RadialBarLegend
                                                toCurrency={utilsStore.toCurrency}
                                                store={store}
                                                showLegend={store.showLegend}
                                                procLimits={store.procLimits}
                                                showProcDetails={store.showProcDetails}
                                                showProcLimitDetails={store.showProcLimitDetails}
                                                hideProcLimitDetails={store.hideProcLimitDetails}
                                                toggleShowLegend={store.toggleShowLegend}
                                                toggleHideLegend={store.toggleHideLegend}
                                                isDemo={store.isDemo}
                                            />
                                        </section>

                                        <section css={`
                                          height: 100%;
                                          width: 100%;
                                        `}>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    top: '15px',
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div style={{
                                                    width: 'calc(100% - 40px)',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}>
                                                    <Dropdown
                                                        id="historical"
                                                        options={store.dropdownOptions}
                                                        value={store.selectedOption}
                                                        onChange={store.selectOption}
                                                        placeholder="Select Month"
                                                        width={'140px'}
                                                        height={'20px'}
                                                    />
                                                    &nbsp; &nbsp;
                                                    <div>Historical</div>
                                                </div>
                                                {store.procCurrent?.length && store.procCurrent.length > 3 && (
                                                    <Paginator
                                                        paginator={store.paginator}
                                                        loading={false}
                                                        fetchTableData={() => {
                                                        }}
                                                        txnCount={store.procCurrent.length}
                                                        height="20px"
                                                        width="25px"
                                                    />
                                                )}
                                            </div>
                                            {store.procHistory && store.procHistory.length && (
                                                <RadialBarLegend
                                                    store={store}
                                                    procLimits={store.procLimitsHistory}
                                                    showProcDetails={store.showProcHistDetails}
                                                    showProcLimitDetails={store.showProcLimitHistDetails}
                                                    hideProcLimitDetails={store.hideProcLimitHistDetails}
                                                    toggleShowLegend={store.toggleShowLegendHist}
                                                    toggleHideLegend={store.toggleHideLegendHist}
                                                    isDemo={store.isDemo}
                                                />
                                            )}
                                        </section>
                                    </div>
                                ) : null}
                        </WidgetBody>
                    </WidgetWrapper>
                </Wrapper>
            )}
        </Observer>
    );
});

const Wrapper = styled.div`
  position: relative;
  grid-area: SsProcLimits;
  width: ${(props) => (props.moveLeft ? '100%' : 'calc(100vw - 210px)')};

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  /* entire container, keeps perspective */

  .flip-container {
    perspective: 1000px;
  }

  /* flip the pane when hovered */

  .flip-container:hover .flipper,
  .flip-container.hover .flipper {
    transform: rotateY(180deg);
  }

  .flip-container,
  .front,
  .back {
    width: 100%;
    height: 130px;
  }

  /* flip speed goes here */

  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }

  /*This makes the flip work correctly
  for firefox but messes up chrome.
  */
  @-moz-document url-prefix() {
    .flipper {
      backface-visibility: hidden;
    }
  }
  /* hide back of pane during swap */

  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* front pane, placed above back */

  .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
  }

  /* back, initially hidden pane */

  .back {
    transform: rotateY(180deg);
  }

  .recharts-wrapper {
    margin: 0 auto;
  }

  .processor {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .midNum {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;
const StyledNote = styled.div`
  position: absolute;
  right: 5px;
  top: 16px;
  height: 10px;
  width: 100%;
  justify-content: flex-end;
  text-align: end;
  align-items: center;
  display: flex;
  font-size: 10px;
  padding: ${({padding}) => padding};
  font-style: italic;

  .note {
    font-size: 12px;
    color: red;
  }

  .noteBody {
    color: ${({theme}) => theme.baseColors.whiteAlpha8};
  }
`;
export default SsProcLimits;
