import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Observer, observer, useLocalStore } from 'mobx-react-lite';
import { _get, useGetWidgetData, useInjectStores } from 'utils/utils';
import { AgGridReact } from 'ag-grid-react';
import { TableHeader, TableWrapper } from '../StyledTable';
import { Dropdown, Error, LoadingSpinner } from 'components/UI';
import { TableHeaderButton } from 'components/UI/Table/TableHeader/StyledResources/StyledTableHeader';
import { Wrapper } from 'styles/styledComponents';
import { widgetService } from 'services';
import { getFileName } from 'utils/utils';
const initialData = [];

const Risk = observer(() => {
  const { dataManagementStore, userStore, utilsStore } = useInjectStores();
  const toCurrencyFormatter = utilsStore.toCurrencyFormatter;
  const toDateFormatter = utilsStore.toDateFormatter;

  const [api, setApi] = useState(null);

  function onGridReady(params) {
    setApi(params.api);
  }

  function isDemoData(data) {
    return store.isDemo ? ' 888800000008888' : data.data.mid;
  }
  const store = useLocalStore(() => ({
    fileName: 'risk',
    loading: false,
    rowData: initialData,
    exportOptions: [
      { key: 1, text: 'EXCEL', value: 'excel' },
      { key: 2, text: 'CSV', value: 'csv' },
    ],
    exportType: 'excel',
    setExportType: (type) => {
      store.exportType = type;
    },
    get isDemo() {
      return _get(userStore, 'user.demo', false);
    },
    get gridOptions() {
      return {
        enableCharts: true,
        enableRangeSelection: true,
        groupMultiAutoColumn: true,
        rowHeight: 33,
        headerHeight: 33,
        reactNext: true,
        sideBar: {
          position: 'left',
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
        },
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: [{ field: 'mid' }],
            defaultColDef: { flex: 1 },
          },
          template:
            '<div style="height: 100%; background-color: transparent; color: "rgba(255,255,255, .8)" padding: 20px; box-sizing: border-box;">' +
            '  <div style="height: 10%; padding: 2px; font-weight: bold;">###### Call Notes  just put the stuff in here</div>' +
            '</div>',
        },
        defaultColDef: {
          sortable: false,
          filter: true,
          resizable: true,
        },
        columnDefs: [
          { headerName: 'Avg Ticket Amt', field: 'avg_tkt_amt', width: 160, pinned: 'left', valueFormatter: isDemoData },
          { headerName: 'Avg Chargeback Amt', field: 'cb_amt_avg', width: 115, pinned: 'left', valueFormatter: toDateFormatter },
          { headerName: 'Risk Status', field: 'cb_cp_rat_netrdr_amt_stat', pinned: 'left', width: 170 },
          { headerName: 'Cp vs Cnp', field: 'cp_vs_cnp', width: 160, pinned: 'left' },
          { headerName: 'End Date', field: 'end_dt', width: 140, type: 'rightAligned' },
          { headerName: 'Merchant Name', field: 'et_merch_nm_dsply', width: 160 },
          { headerName: 'First Transaction', field: 'first_sale_dt', width: 140 },
          { headerName: 'High Ticket Amt', field: 'hi_tkt_amt', width: 120 },
          { headerName: 'MCC', field: 'mcc', width: 120 },
          { headerName: 'Monthly Credit Limit', field: 'mo_cr_lmt', width: 190 },
          { headerName: 'Monthly Credit Rate', field: 'mo_cr_lmt_rat', width: 160 },
          { headerName: 'Transaction Amt', field: 'sale_amt', width: 120 },
          { headerName: 'Transaction Count', field: 'sale_cnt', width: 120 },
          { headerName: 'Start Date', field: 'start_dt', width: 120 },
          { headerName: 'End Of Month', field: 'txn_dt_eom', width: 120 },
        ],
        overlayLoadingTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">Please wait while your rows are loading</span>',
        overlayNoRowsTemplate:
          '<span style="padding: 10px; background-color: #041c2f; border: 1px solid rgba(255,255,255, .2);">No Data for selected Time Period</span>',
      };
    },
    setData(widgetData) {
      const { status, data, error } = widgetData;
      if (status === 'loading') this.loading = true;
      if (error) this.loading = false;
      if (data) {
        store.fileName = getFileName(userStore, data?.config, 'risk');

        this.rowData = data.values;
        this.loading = false;
      }
    },
  }));

  const widgetData = useGetWidgetData({ name: 'reports', query: widgetService.risk }, 'data', !!dataManagementStore?.newQuery);

  useEffect(() => {
    store.setData(widgetData);
  }, [widgetData]);

  return (
    <Observer>
      {() => (
        <TableWrapper height="100%" margin={'0 4px'}>
          <TableHeaderWrapper padding="6px">
            <TableHeader height="auto" padding="0" margin="auto">
              Risk Reporting
            </TableHeader>
            <Dropdown
              className="exportDropdown"
              caretRight={10}
              options={store.exportOptions}
              onChange={store.setExportType}
              value={store.exportType}
              width="80px"
              height="25px"
              margin="0"
              border
            />
            <TableHeaderButton
              height="27px"
              onClick={() => {
                if (store.exportType === 'csv') {
                  api.exportDataAsCsv({ fileName: store.fileName });
                } else {
                  api.exportDataAsExcel({ fileName: store.fileName });
                }
              }}
            >
              Export
            </TableHeaderButton>
          </TableHeaderWrapper>
          <GridWrapper>
            <div
              id="myGrid"
              className={'ag-theme-alpine-dark'}
              style={{
                height: '100%',
                minHeight: '300px',
                width: '100%',
                position: 'relative',
                borderRadius: '5px',
              }}
            >
              {store.loading ? (
                <Wrapper>
                  <LoadingSpinner size={'200px'} />
                </Wrapper>
              ) : store.error ? (
                <Error error={store.error} />
              ) : store.rowData ? (
                <AgGridReact
                  rowData={store.rowData}
                  onGridReady={onGridReady}
                  gridOptions={store.gridOptions}
                  popupParent={document.getElementById('myGrid')}
                />
              ) : null}
            </div>
          </GridWrapper>
        </TableWrapper>
      )}
    </Observer>
  );
});
const TableHeaderWrapper = styled.div`
  padding: ${({ padding }) => padding || ''};
  display: flex;
`;
const GridWrapper = styled.div`
  height: 100%;

  .ag-theme-alpine-dark {
    --ag-foreground-color: ${({ theme }) => theme.baseColors.whiteAlpha8};
    --ag-background-color: #032845;
    --ag-odd-row-background-color: #041c2f;
    --ag-header-background-color: #041c2f;
    --ag-border-color: rgba(255, 255, 255, 0.1);
    .ag-header-row {
      height: 30px;
    }
    .ag-cell {
      line-height: 30px;
      font-size: 13px;
    }
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 31px;
    }
    .topLevel {
      background-color: #001329 !important;
    }
    .secondLevel {
      background-color: #032845 !important;
    }
  }
`;

export default Risk;
