import {applySnapshot, destroy, flow, getSnapshot, types, detach} from 'mobx-state-tree';
import {clientInfoModel, midModel, limitsModel, gwsProcsModel, pricingPlanModel} from './models';
import {commonService, adminService} from 'services/service';
import {_get} from 'utils/utils';
import axios from 'axios';
import {ApiCreds, ScrapeCreds, CybersourceScrape, GoldstarApi} from './CredentialModels';
import Moment from "moment";

const {array, optional} = types;

export const editClientStore = types
    .model('editClientFormStore', {
        clientInfo: optional(clientInfoModel, () => clientInfoModel.create()),
        mids: optional(array(midModel), []),
        deleteMids: optional(array(midModel), []),
        newMids: optional(array(midModel), []),
        initialMids: optional(array(midModel), []),
        pricingPlan: optional(pricingPlanModel, () => pricingPlanModel.create()),
    })
    .volatile((self) => ({
        loading: false,
        selectedClient: null,
        clients: [],
    }))
    .views((self) => ({
        get postEditClientInfo() {
            return {
                ...self.clientInfo,
                mids: [...self.mids, ...self.newMids],
                pricingPlan: self.pricingPlan,
            };
        },
    }))
    .actions((self) => {
        function setClients(clients) {
            self.clients = clients;
        }

        function setMids(mid, integrations, procLimits) {
            const gatewaysRes = integrations.gateway;
            const processorsRes = integrations.processor;
            let newMids = [],
                gateways = [],
                processors = [];
            gatewaysRes && gatewaysRes.map((gateway) => {
                const newGwProc = gwsProcsModel.create({
                    integration: gateway.integration,
                    integration_type: gateway.integration_type,
                    midNumber: gateway.midNumber,
                    dataSource: gateway.dataSource,
                    credentials: ApiCreds.create(),
                });
                gateways.push(newGwProc);
            });
            processorsRes && processorsRes.map((proc) => {
                const newGwProc = gwsProcsModel.create({
                    integration: proc.integration,
                    integration_type: proc.integration_type,
                    midNumber: proc.midNumber,
                    dataSource: proc.dataSource,
                    credentials: ApiCreds.create(),
                });
                processors.push(newGwProc);
            })
            newMids.push(
                midModel.create({
                    // id: mid,
                    procLimit: [
                        limitsModel.create({
                            id: procLimits[0].id,
                            limitEndDate: procLimits[0].limitEndDate,
                            limitStartDate: procLimits[0].limitStartDate,
                            midNum: procLimits[0].midNum,
                            monthlyProcLimit: procLimits[0].monthlyProcLimit,
                        }),
                    ],
                    gateways,
                    processors,
                    mid: procLimits[0].midNum,
                    // midDescriptor: mid.midDescriptor,
                })
            );

            self.mids = newMids;
        }

        function resetMidState(mids) {
            applySnapshot(self, self.midState);
        }

        function saveMidState() {
            self.midState = getSnapshot(self);
        }

        function addMid() {
            self.newMids.push(
                midModel.create({
                    processors: [gwsProcsModel.create()],
                    gateways: [gwsProcsModel.create()],
                    procLimit: [limitsModel.create()],
                })
            );
        }

        function setClientActive() {
            self.clientActive = !self.clientActive;
        }

        function setSelectedClientId(val) {
            self.selectedClientID = val;
            self.id = val;
        }

        function setSelectedClientMid(val) {
            self.selectedClientMid = val;
        }

        function setClientName(e) {
            self.clientName = e.target.value;
        }

        function setActive(e) {
            self.active = e.target.value;
        }

        function setFunctCurrID(value) {
            self.functCurrID = value;
        }

        function setTmzID(value) {
            self.tmzID = value;
        }

        function setContactFName(e) {
            self.contactFirstName = e.target.value;
        }

        function setContactLName(e) {
            self.contactLastName = e.target.value;
        }

        function setAddr1(e) {
            self.addr1 = e.target.value;
        }

        function setAddr2(e) {
            self.addr2 = e.target.value;
        }

        function setAddr3(e) {
            self.addr3 = e.target.value;
        }

        function setCity(e) {
            self.city = e.target.value;
        }

        function setStProv(e) {
            self.stProv = e.target.value;
        }

        function setZip(e) {
            self.zip = e.target.value;
        }

        function setCtry(value) {
            self.country = value;
        }

        function setClientContactPh(e) {
            self.clientContactPhone = e.target.value;
        }

        function setClientContactEmail(e) {
            self.clientContactEmail = e.target.value;
        }

        function setFein(e) {
            self.fein = e.target.value;
        }

        function setMccSic(e) {
            self.mccSic = e.target.value;
        }

        function setPricingPlan(value) {
            self.pricingPlanID = value;
        }

        function addClientMidInfo(mids) {
            self.mids.push(mids);
        }

        function afterCreate() {
            self.initialState = getSnapshot(self);
        }

        function resetMids() {
            self.mids = self.initialMids;
            self.newMids = [];
        }

        function resetForm() {
            applySnapshot(self, self.initialState);
        }

        function remove(item) {
            detach(item);
            self.deleteMids.push(item);
        }

        const createClientError = flow(function* clientError(error, notify, location, history) {
            console.log('error', error);
            const messages = _get(error, 'response.data.messages') || [_get(error, 'response.data.message')] || [_get(error, 'response.data', 'Error creating client')];
            messages.map(message => {
                notify({
                    duration: 9000,
                    group: 'topRight',
                    text: message,
                    type: 'error'
                });
            })
            if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                try {
                    yield commonService.logout({
                        id: _get(self, 'user.id'),
                    });
                } catch (error) {
                    console.error('error', error);
                }
                if (!location.pathname.includes('/login')) {
                    history.push('/login');
                }
                axios.defaults.headers.common['authorization'] = null;
                window.localStorage.removeItem('eygSessionToken');
            }
            console.error(error);
        });

        const createMid = flow(function* createMid(midnum, clientId, procLimits, notify, location, history) {
            try {
                 yield adminService.createMid({
                    clientID: clientId.toString(),
                    midNum: midnum,
                    startDt: Moment().startOf('day').toISOString()
                });
                 yield createProcLimit(midnum, procLimits, notify, location, history)

            } catch (error) {
                yield createClientError(error, notify, location, history);
            }
        });

        const updateMid = flow(function* updateMid(id, midnum, clientId, procLimits, notify, location, history) {
            try {
                yield updateProcLimit(id, midnum, procLimits, notify, location, history)
            } catch (error) {
                yield createClientError(error, notify, location, history);
            }
        });

        const deleteMid = flow(function* deleteMid( midnum, clientId, notify, location, history) {
            try {
                yield adminService.deleteMid({
                    clientID: clientId.toString(),
                    midNum: midnum,
                    startDt: Moment().startOf('day').toISOString()
                });

                notify({
                    duration: 3000,
                    group: 'topRight',
                    text: 'Successfully removed mid',
                });

            } catch (error) {
                yield createClientError(error, notify, location, history);
            }
        });
        const updateProcLimit = flow(function* updateProcLimit(id, midnum, procLimits, notify, location, history) {
            procLimits && procLimits.length && procLimits.map(async (limit) => {
                const {
                    limitEndDate,
                    limitStartDate,
                    monthlyProcLimit
                } = limit;
                try {
                    const procLimitRes = await adminService.updateProcLimit({
                        id: limit.id,
                        midNum: midnum,
                        startDt: limitStartDate,
                        endDt: limitEndDate,
                        monthlyProcLimit: monthlyProcLimit
                    });
                    notify({
                        duration: 3000,
                        group: 'topRight',
                        text: 'Successfully updated proc limit',
                    });
                } catch (error) {
                    const messages = _get(error, 'response.data.messages') || [_get(error, 'response.data.message')] || [_get(error, 'response.data', 'Error creating client')];
                    messages.map(message => {
                        notify({
                            duration: 9000,
                            group: 'topRight',
                            text: message,
                            type: 'error'
                        });
                    })
                    if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                        try {
                            await commonService.logout({
                                id: _get(self, 'user.id'),
                            });
                        } catch (error) {
                            console.error('error', error);
                        }
                        if (!location.pathname.includes('/login')) {
                            history.push('/login');
                        }
                        axios.defaults.headers.common['authorization'] = null;
                        window.localStorage.removeItem('eygSessionToken');
                    }
                }
            });
        });

        const createProcLimit = flow(function* createProcLimit(midnum, procLimits, notify, location, history) {
            procLimits && procLimits.length && procLimits.map(async (limit) => {
                const {
                    limitEndDate,
                    limitStartDate,
                    monthlyProcLimit
                } = limit;
                try {
                    const procLimitRes = await adminService.createProcLimit({
                        midNum: midnum,
                        startDt: limitStartDate,
                        endDt: limitEndDate,
                        monthlyProcLimit: monthlyProcLimit
                    });
                    notify({
                        duration: 3000,
                        group: 'topRight',
                        text: 'Successfully created mid processor limit',
                    });
                } catch (error) {
                    const messages = _get(error, 'response.data.messages') || [_get(error, 'response.data.message')] || [_get(error, 'response.data', 'Error creating client')];
                    messages.map(message => {
                        notify({
                            duration: 9000,
                            group: 'topRight',
                            text: message,
                            type: 'error'
                        });
                    })
                    if (_get(error, 'response.status') === 401 && location.pathname !== '/login') {
                        try {
                            await commonService.logout({
                                id: _get(self, 'user.id'),
                            });
                        } catch (error) {
                            console.error('error', error);
                        }
                        if (!location.pathname.includes('/login')) {
                            history.push('/login');
                        }
                        axios.defaults.headers.common['authorization'] = null;
                        window.localStorage.removeItem('eygSessionToken');
                    }
                }
            });
        });

        const submitEditClient = flow(function* submitEditClient(notify, location, history) {
            self.loading = true;

            const clientId = self.clientInfo.id;

            if (clientId) {
                self.mids.map(async ({id, mid, processors, gateways, procLimit}) => {
                    await updateMid(id, mid, clientId, procLimit, notify, location, history);
                });
                self.newMids.map(async ({mid, processors, gateways, procLimit}) => {
                     await createMid(mid, clientId, procLimit, notify, location, history);
                });
                self.deleteMids.map(async ({id, mid, processors, gateways, procLimit}) => {
                    await deleteMid(mid, clientId, notify, location, history);
                });
            }

            self.loading = false;
        });

        return {
            remove,
            setClients,
            resetMidState,
            saveMidState,
            setMids,
            setClientName,
            setActive,
            setFunctCurrID,
            setTmzID,
            setContactFName,
            setContactLName,
            setAddr1,
            setAddr2,
            setAddr3,
            setCity,
            setStProv,
            setZip,
            setCtry,
            setClientContactPh,
            setClientContactEmail,
            setFein,
            setMccSic,
            setPricingPlan,
            setSelectedClientId,
            setSelectedClientMid,
            setClientActive,
            addClientMidInfo,
            afterCreate,
            submitEditClient,
            resetForm,
            addMid,
        };
    });
